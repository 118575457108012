import { Flex, Loader, Text } from "@mantine/core";
import { CheckMark } from "../../common-ui";
import { type Partner } from "../../shared-types";
import { classNames } from "../../shared-utils";
import styles from "./EditSessionSummaryModal.module.css";
import { getPartnerTool } from "../utils";

interface SendSummaryNotificationProps {
    partner: Partner;
    loading: boolean;
    summarySendSucceeded: boolean;
}

export function SendSummaryNotification({ partner, loading, summarySendSucceeded }: SendSummaryNotificationProps) {
    function getNotificationText() {
        if (loading) {
            return "Generating summary";
        }

        if (summarySendSucceeded) {
            return "Summary generated and saved—no action needed";
        }
        return `Notes copied. Add to ${getPartnerTool(partner)} and end the session`;
    }

    function getBackgroundClass() {
        if (loading) {
            return styles.loadingBg;
        }
        return styles.defaultBg;
    }

    return (
        <Flex className={classNames(styles.notificationWrapper, getBackgroundClass())}>
            <>
                {loading ? <Loader size="xs" color="primary.2" /> : <CheckMark fill="white" width={18} />}
                <Text c={loading ? "primary.2" : "white"} pl="1rem" fw="var(--mantine-font-weight-heavy)" fz="xs">
                    {getNotificationText()}
                </Text>
            </>
        </Flex>
    );
}
