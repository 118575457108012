import { createContext, useContext, useEffect } from "react";
import { getLogger } from "@expert/logging";
import { SendMessageToTenantType, useWebsocketInternal } from "../hooks/useWebsocket";
import reactUseWebSocket from "react-use-websocket";

type SendJsonMessage = ReturnType<typeof reactUseWebSocket>["sendJsonMessage"];

const WebSocketContext = createContext<{
    sendWebSocketMessage?: SendJsonMessage;
    sendMessageToTenant?: SendMessageToTenantType;
}>({
    sendWebSocketMessage: undefined,
    sendMessageToTenant: undefined,
});

interface WebSocketProviderProps {
    token: string;
    identity: string;
    webSocketUrl: string;
    children?: React.ReactNode;
}

const logger = getLogger({ module: "ExpertWorkspaceWebSocketProvider" });

export function ExpertWorkspaceWebSocketProvider({ token, identity, webSocketUrl, children }: WebSocketProviderProps) {
    const { sendWebSocketMessage, sendMessageToTenant, loading, readyState } = useWebsocketInternal(
        token,
        identity,
        import.meta.env.MODE as "development" | "production",
        webSocketUrl,
    );
    // TODO: Decide how do we want to handle WS reconnect/disconnect
    // if (loading) return <GlobalLoader msg="Creating websocket connection..." />;
    useEffect(() => {
        logger.trace(`==== WEB SOCKET STATE IS ${readyState}, LOADING: ${loading} ====`);
    }, [loading, readyState]);

    return (
        <WebSocketContext.Provider value={{ sendWebSocketMessage, sendMessageToTenant }}>
            {children}
        </WebSocketContext.Provider>
    );
}

export function useExpertWorkspaceWebSocket() {
    const context = useContext(WebSocketContext);
    return context;
}
