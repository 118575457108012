export function RedAsterisk() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6668 7.33264H9.1555L11.9108 2.55998L10.7562 1.89331L8.00016 6.66598L5.24483 1.89331L4.09016 2.55998L6.8455 7.33264H1.3335V8.66598H6.84616L4.0895 13.44L5.24416 14.1066L8.00016 9.33331L10.7568 14.1066L11.9115 13.44L9.15483 8.66598H14.6668V7.33264Z"
                fill="#FFC9C9"
            />
        </svg>
    );
}
