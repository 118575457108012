import { Dayjs } from "dayjs";
import { api } from "../shared-utils";
import { env } from "../../config";
import { getSessionId } from "../sdk";

interface ExpertChecklistData {
    employeeId: number;
    employeeName?: string;
    numCallsWithTransitionVerbatim: number;
    numCallsWithPriceVerbatim: number;
    numCallsWithAskForSaleVerbatim: number;
    numCallsWithProtectionVerbatim: number;
    numCallsWithFutureDevicesVerbatim: number;
    numCallsWithNonVerizonVerbatim: number;
    numCallsWithChecklist: number;
    numEnrollments: number;
}

export const getCoachStats = async (startDate: Dayjs, endDate: Dayjs): Promise<ExpertChecklistData[]> => {
    return await api
        .extend(() => ({
            prefixUrl: env.gamificationBaseApiUrl,
            hooks: {
                beforeRequest: [
                    (request) => {
                        request.headers.set("x-ewp-correlation-id", getSessionId());
                    },
                ],
            },
        }))
        .get(`v1/coach-view?startDate=${startDate.format("MM-DD-YYYY")}&endDate=${endDate.format("MM-DD-YYYY")}`, {
            retry: 0,
        })
        .json<ExpertChecklistData[]>();
};
