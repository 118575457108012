import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface OfferChecklistState {
    data: {
        PROTECTION: boolean;
        NON_VERIZON_DEVICES: boolean;
        TECH_SUPPORT: boolean;
        FUTURE_DEVICES: boolean;
        IN_HOME_INSTALLATION: boolean;
        IN_STORE_SERVICES: boolean;
        ASK_SALE: boolean;
        BILLING: boolean;
        TRANSITION: boolean;
    };

    setData: (newData: OfferChecklistState["data"]) => void;
    resetData: () => void;
}

export const useOfferChecklistStore = create<OfferChecklistState>()(
    persist(
        (set) => ({
            data: {
                PROTECTION: false,
                NON_VERIZON_DEVICES: false,
                TECH_SUPPORT: false,
                FUTURE_DEVICES: false,
                IN_HOME_INSTALLATION: false,
                IN_STORE_SERVICES: false,
                ASK_SALE: false,
                BILLING: false,
                TRANSITION: false,
            },
            setData: (newData) => set((state) => ({ ...state.data, data: newData })),
            resetData: () =>
                set((state) => ({
                    ...state,
                    data: {
                        PROTECTION: false,
                        NON_VERIZON_DEVICES: false,
                        TECH_SUPPORT: false,
                        FUTURE_DEVICES: false,
                        IN_HOME_INSTALLATION: false,
                        IN_STORE_SERVICES: false,
                        ASK_SALE: false,
                        BILLING: false,
                        TRANSITION: false,
                    },
                })),
        }),
        { name: "offer-checklist-store", skipHydration: true },
    ),
);
