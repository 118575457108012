/* eslint-disable prefer-smart-quotes/prefer */
import { Accordion, Divider, Flex, Popover, ScrollArea, Text, UnstyledButton } from "@mantine/core";
import { useEffect, useState } from "react";
import { ChecklistIcon, GreenCheckMark, GreenCheckMarkFilled, RedAsterisk, UncheckedRadioIcon } from "../Icons";
import { OfferChecklistState, useOfferChecklistStore } from "../../stores/useOfferChecklistStore";
import classes from "./FullOfferChecklist.module.css";
import { offerChecklistData, OfferChecklistItem, OfferChecklistSection } from "./offerChecklistData";
import { useAnalytics } from "../../../../analytics";

export function FullOfferChecklist(): JSX.Element {
    const { data } = useOfferChecklistStore();
    const { dispatcher } = useAnalytics();
    const accordionCloseTime = 1000;

    const [renderChevron, setRenderChevron] = useState([false, false, false]);
    const [accordionValue, setAccordionValue] = useState<string[]>([offerChecklistData[0].heading]);

    const renderRow = (i: OfferChecklistItem, data: OfferChecklistState["data"]) => {
        return (
            <Flex key={i.key} gap={2} className={data[i.key] ? classes.detectedText : ""} mb={2}>
                {data[i.key] ? <GreenCheckMark /> : <UncheckedRadioIcon />}
                {i.displayString}
            </Flex>
        );
    };

    const onChangeFunction = (section: string[]) => {
        void dispatcher.dispatchAction("OfferChecklist_opened_sections", section.join(", "));
        setAccordionValue(section);
    };

    const handleAccordionUpdate = (section: OfferChecklistSection) => {
        if (section.disabled(data)) {
            void dispatcher.dispatchAction("OfferChecklist_AllDetected_Section", section.heading);
            setTimeout(() => {
                setAccordionValue((prev) => prev.filter((i) => i !== section.heading));
            }, accordionCloseTime);
            setTimeout(() => {
                setRenderChevron((prev) =>
                    prev.map((i, index) => (index === offerChecklistData.indexOf(section) ? true : i)),
                );
            }, accordionCloseTime + 200);
        }
    };

    useEffect(() => {
        offerChecklistData.forEach((section) => {
            handleAccordionUpdate(section);
            if (
                !section.disabled(data) &&
                !accordionValue.includes(section.heading) &&
                section.content.some((i) => data[i.key])
            ) {
                setAccordionValue((prev) => [...prev, section.heading]);
            }
        });

        for (let i = 0; i < offerChecklistData.length - 1; i++) {
            if (offerChecklistData[i].disabled(data) && !offerChecklistData[i + 1].disabled(data)) {
                //expand next section
                setAccordionValue((prev) => [...prev, offerChecklistData[i + 1].heading]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...Object.values(data), data]);

    const toolTip = (
        <Flex c="dark.1" pb={24} pt={6}>
            <Popover width={260}>
                <Popover.Target>
                    <UnstyledButton td="underline" fz={12} c="dark.1">
                        View full billing details
                    </UnstyledButton>
                </Popover.Target>
                <Popover.Dropdown>
                    <Text fz={14} c="dark.1">
                        “Its $25 per month, plus tax. Charges begin upon enrollment. Your next bill will include a
                        prorated amount for this month and next month&apos;s full amount. After that, you&apos;ll just
                        see the monthly fee.”
                    </Text>
                </Popover.Dropdown>
            </Popover>
        </Flex>
    );

    return (
        <Flex direction="column" className={classes.overflow} w="100%">
            <ScrollArea className={classes.scrollArea}>
                <Flex h={52} c="dark.2" pl={24} pt={16} pb={8} className={classes.topHeader}>
                    <ChecklistIcon />
                    <Flex fw={700} fz={12} pl={4}>
                        OFFER CHECKLIST
                    </Flex>
                </Flex>
                <Divider size="xs" color="dark.4" />
                <Accordion multiple value={accordionValue} onChange={onChangeFunction}>
                    {offerChecklistData.map((section, index) => (
                        <Accordion.Item key={section.heading} value={section.heading}>
                            <Accordion.Control
                                fz={14}
                                classNames={{
                                    label: classes.accordionHeading,
                                    chevron: section.disabled(data) ? classes.chevron : "",
                                    control: section.disabled(data) ? classes.controlDisabled : classes.control,
                                }}
                                disabled={section.disabled(data)}
                                chevron={
                                    section.disabled(data) && renderChevron[index] ? (
                                        <Flex align="center" gap={4} className={classes.animateDone}>
                                            <GreenCheckMarkFilled />
                                            Done
                                        </Flex>
                                    ) : undefined
                                }
                            >
                                {section.heading}
                            </Accordion.Control>
                            <Accordion.Panel classNames={{ content: classes.content }}>
                                {section.content.map((i) => (
                                    <>
                                        {i.redText ? (
                                            <>
                                                <Flex pb={6} className={data[i.key] ? classes.animateOk : ""}>
                                                    {!data[i.key] && "Say these exact words"}
                                                </Flex>
                                                <Flex className={data[i.key] ? classes.redBgDetected : classes.redBg}>
                                                    {renderRow(i, data)}
                                                    {!data[i.key] && <RedAsterisk />}
                                                </Flex>
                                                {i.billingText && !data[i.key] && toolTip}
                                            </>
                                        ) : (
                                            <Flex className={classes.whiteSpace}>{renderRow(i, data)}</Flex>
                                        )}
                                    </>
                                ))}
                            </Accordion.Panel>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </ScrollArea>
        </Flex>
    );
}
