export function GreenCheckMarkFilled() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9998 21.2C17.0808 21.2 21.1998 17.0811 21.1998 12C21.1998 6.91903 17.0808 2.80005 11.9998 2.80005C6.91879 2.80005 2.7998 6.91903 2.7998 12C2.7998 17.0811 6.91879 21.2 11.9998 21.2Z"
                fill="#20C997"
                stroke="#20C997"
                strokeWidth="1.44"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.8002 7.99951L11.2002 15.5995L7.2002 12.3995"
                stroke="#0F1113"
                strokeWidth="1.8"
                strokeLinejoin="round"
            />
        </svg>
    );
}
