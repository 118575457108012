import { getLogger } from "@expert/logging";
import { getSessionId } from "../../../sdk";
import { getGamificationApiClient } from "../../../sales-tools/home-product/api/api";
import { type Reaction } from "../../models";

const logger = getLogger({
    module: "send-reaction",
    supportTeam: "sales",
});

export const sendReactionV2 = async (targetExpertId: string, reactionType: Reaction) => {
    const childLogger = logger.child({ targetExpertId, reactionType, sessionId: getSessionId() });
    try {
        logger.info("Sending reaction");
        const reactionApi = getGamificationApiClient();
        return await reactionApi.post("v1/reaction", { json: { targetExpertId, reactionType } }).json<boolean>();
    } catch (err: unknown) {
        childLogger.error({ err }, "sendReaction failure");
        return false;
    }
};
