import { ActionIcon, CopyButton as BaseCopyButton } from "@mantine/core";
import { CheckMark, CopyIcon } from "../../assets";

export function CopyButton({ value }: { value: string }): JSX.Element | null {
    return (
        <BaseCopyButton value={value}>
            {({ copied, copy }) => {
                return (
                    <ActionIcon color="text" onClick={copy} variant="subtle" id="copy-action-icon">
                        {copied ? <CheckMark height="1rem" /> : <CopyIcon height="1rem" />}
                    </ActionIcon>
                );
            }}
        </BaseCopyButton>
    );
}
