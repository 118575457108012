import { type Partner } from "../../shared-types";
import { getPartnerTool } from "../utils";

export const MAX_SUMMARY_LENGTH = 930;

export const getTimeRemaining = (expirationTimestamp: number | undefined) => {
    return expirationTimestamp ? Math.max(expirationTimestamp - Date.now(), 0) : 0;
};

export function getNotificationText(partner: Partner, isValidAccountNumber: boolean) {
    if (isValidAccountNumber) {
        return `Summary will be sent to ${getPartnerTool(partner)}`;
    }

    return `Auto-send failed. Please copy and paste to ${getPartnerTool(partner)} manually.`;
}
