import { getLogger } from "@expert/logging";
import { Group } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { shallow } from "zustand/shallow";
import { useToast } from "../../common-ui";
import { useVerifiedCustomerStore } from "../../customer-info";
import { isVoiceTask, useAgentSdk, useAgentStore, usePartner, useSession } from "../../sdk";
import { getHashedValue, removeCountryCode, useFeatureFlag } from "../../shared-utils";
import { useSessionSummaryStore } from "../store/useSessionSummaryStore";
import { validateSummary } from "../validateSummary";
import { getBackupGaiaSummary, getGaiaSummary } from "../api/sessionSummaryApiClient";
import { getAppConfigSingleton } from "../../config/appConfigSingleton";
import { useSessionSummaryDispatchActions } from "../components/hooks";
import { EditSessionSummaryModal } from "./EditSessionSummaryModal";
import styles from "./EditSessionSummaryModal.module.css";
import { addSessionSummaryMessage } from "@soluto-private/expert-workspace-timeline";

const logger = getLogger({ module: "sessionSummary" });

export function DeprecatedSessionSummary(): JSX.Element | null {
    const appConfig = getAppConfigSingleton();
    const [mdn, accountNumber] = useVerifiedCustomerStore((state) => [
        state.customer?.mdn,
        state.customer?.accountNumber,
    ]);

    const agentSdk = useAgentSdk();
    const partner = usePartner();
    const accountNumberLength = appConfig.partnersConfig[partner].accountNumberLength;
    const validAccountNumber = accountNumberLength ? accountNumber?.length === accountNumberLength : false;

    const { dispatchSessionSummaryStats, dispatchSessionSubmit } = useSessionSummaryDispatchActions();

    const [setCanAppend, setSubmitted, autogeneratedSummary] = useSessionSummaryStore((state) => [
        state.setCanAppend,
        state.setSubmitted,
        state.autogeneratedSummary,
    ]);

    const [summaryLoaded, setSummaryLoaded] = useState(false);

    const toast = useToast();
    const [opened, { open: openModal, close: closeModal }] = useDisclosure(false);

    const agentActivity = useAgentStore(useShallow((state) => state.activity));
    const activeSession = useSession();
    const { summary, submitted, setSessionSummary } = useSessionSummaryStore((state) => state, shallow);
    const activeTask = activeSession.currentTask;
    const expirationTimestamp = activeSession.wrappingState?.expirationTimestamp;
    if (activeTask && !isVoiceTask(activeTask)) {
        throw new Error("Unsupported task channel. SessionSummary should not be rendered without a voice task");
    }

    const agentCallSid =
        activeTask?.agentCallId ??
        activeSession.tasks.filter(isVoiceTask).findLast((task) => task.agentCallId !== undefined)?.agentCallId;

    const shouldAutoGenerateSummary = activeTask?.locale !== "es-mx";

    // A wrapping task or a newly wrapping agent means we are entering session summary
    const inWrappingState = activeTask?.status === "wrapping" || agentActivity === "Wrapping";

    const taskIsActive = activeTask?.status === "assigned";
    const hasImmediateCallBack = activeSession.callbackState?.callbackType === "CallbackNow";
    const { data: isGaiaMultiStepSummaryEnabled, isLoading: isLoadingSummaryFeature } = useFeatureFlag(
        "isGaiaMultiStepSummaryEnabled",
        { partner },
    );

    const gaiaSummaryModel = useMemo(() => {
        return isGaiaMultiStepSummaryEnabled ? "post-call-multistep-summarization" : "post-call-summarization";
    }, [isGaiaMultiStepSummaryEnabled]);

    async function handleWrappingExpired() {
        await handleEndSession("WrappingTimeExpired");
    }

    const handleCopyAndSendSummary = async () => {
        try {
            void navigator.clipboard.writeText(summary);
        } catch (err) {
            logger.error({ err }, "Error copying session summary");
        }
        await onSessionSummaryCompleted(summary, true);
    };

    async function handleEndSession(reason: "AgentTerminatedWrapping" | "WrappingTimeExpired") {
        if (validAccountNumber) await onSessionSummaryCompleted(summary);
        else await handleCopyAndSendSummary();
        logger.trace({ reason, partner }, "Ending session wrapup");
        await agentSdk.endWrapup(reason, partner);
    }

    const setSessionSummaryFromGaia = useCallback(async () => {
        logger.trace({ agentCallSid, sessionId: activeSession.id, partner }, "Gaia summary fetch check");
        const generatedSummary = agentCallSid
            ? await getGaiaSummary({ callSid: agentCallSid, sessionId: activeSession.id, partner }, gaiaSummaryModel)
            : await getBackupGaiaSummary({ sessionId: activeSession.id, partner }, gaiaSummaryModel);

        setSessionSummary({ value: generatedSummary, autoGenerated: true });
        setSummaryLoaded(true);
    }, [agentCallSid, activeSession.id, partner, setSessionSummary, setSummaryLoaded, gaiaSummaryModel]);

    useEffect(() => {
        if (!shouldAutoGenerateSummary) {
            setSummaryLoaded(true);
            return;
        }

        if (opened && inWrappingState && !isLoadingSummaryFeature) {
            void setSessionSummaryFromGaia();
        }
    }, [setSessionSummaryFromGaia, isLoadingSummaryFeature, inWrappingState, shouldAutoGenerateSummary, opened]);

    useEffect(() => {
        if (hasImmediateCallBack) return;

        if (inWrappingState) {
            openModal();
        } else if (taskIsActive) {
            setCanAppend(true);
        }
    }, [hasImmediateCallBack, inWrappingState, taskIsActive, setCanAppend, openModal]);

    const shouldSendSummary = !submitted && summary;

    const onSessionSummaryCompleted = useCallback(
        async (update?: string, skipSend?: boolean) => {
            const completedSummary = update ?? summary;
            if (completedSummary) addSessionSummaryMessage({ summary: completedSummary, header: "Call summary" });

            if (skipSend) {
                dispatchSessionSummaryStats({
                    agentCallSid,
                    update: completedSummary,
                    autogeneratedSummary,
                    expirationTimestamp,
                    timeRanOut: false,
                    summaryWasCopiedToClipboard: true,
                    summaryWasSubmittedViaACSS: false,
                    summaryWasSubmittedViaPartnerTool: false,
                    gaiaSummaryModel,
                });
                toast.success("Copied session summary");
                setSubmitted(true);
                closeModal();
                return;
            }

            const { isError, response } = validateSummary({ mdn, validAccountNumber, summary: update });

            if (!mdn || isError) {
                logger.error(response ?? "Something went wrong");
                toast.error("Something went wrong");
                closeModal();
                return;
            }

            const mdnFormatted = removeCountryCode(mdn);
            const mdnHashed = await getHashedValue(mdnFormatted);

            const extraSessionSummaryPayloadProps = appConfig.partnersConfig[partner].extraSessionSummaryPayloadProps;

            const sessionSummaryPayload = {
                accountNo: accountNumber,
                remarkText: update,
                ...extraSessionSummaryPayloadProps,
            };

            // Summary -> partner tool (ACSS/OPUS)
            dispatchSessionSubmit({ agentCallSid, mdn: mdnFormatted, mdnHashed, sessionSummaryPayload });

            dispatchSessionSummaryStats({
                agentCallSid,
                update: completedSummary,
                autogeneratedSummary,
                expirationTimestamp,
                summaryWasCopiedToClipboard: false,
                summaryWasSubmittedViaACSS: true,
                summaryWasSubmittedViaPartnerTool: true,
                timeRanOut: false,
                gaiaSummaryModel,
            });

            setSubmitted(true);
            closeModal();
            toast.success("Summary sent");
        },
        [
            mdn,
            accountNumber,
            setSubmitted,
            partner,
            closeModal,
            toast,
            dispatchSessionSummaryStats,
            summary,
            dispatchSessionSubmit,
            agentCallSid,
            autogeneratedSummary,
            expirationTimestamp,
            appConfig.partnersConfig,
            validAccountNumber,
            gaiaSummaryModel,
        ],
    );

    const sendSummary = useCallback(() => {
        dispatchSessionSummaryStats({
            agentCallSid,
            update: summary,
            autogeneratedSummary,
            expirationTimestamp: 0,
            timeRanOut: true,
            summaryWasCopiedToClipboard: true,
            summaryWasSubmittedViaACSS: false,
            summaryWasSubmittedViaPartnerTool: false,
        });

        toast.success("Session summary copied to clipboard");
        void navigator.clipboard.writeText(summary);
    }, [agentCallSid, autogeneratedSummary, dispatchSessionSummaryStats, summary, toast]);

    const onDismiss = useCallback(
        ({ checkSummary = true } = {}) => {
            logger.trace("Session summary dismissed");
            closeModal();
            if (checkSummary && shouldSendSummary) {
                sendSummary();
            }
        },
        [closeModal, sendSummary, shouldSendSummary],
    );

    return (
        <Group className={styles.tempTimeline}>
            <EditSessionSummaryModal
                loading={(!summaryLoaded || isLoadingSummaryFeature) && shouldAutoGenerateSummary}
                onDismiss={onDismiss}
                onExpired={handleWrappingExpired}
                validAccountNumber={validAccountNumber}
                endSession={handleEndSession}
                opened={opened}
            />
        </Group>
    );
}
