import { getAppConfigSingleton } from "../config/appConfigSingleton";
import { Partner } from "../shared-types";

export function getPartnerTool(partner: Partner) {
    const appConfig = getAppConfigSingleton();
    const partnerTool = appConfig.partnersConfig[partner].partnerTool;

    if (partnerTool) {
        return partnerTool;
    } else {
        console.log(`No partner tool found for this partner: ${partner}`);
    }
}
