const deviceActivationFeatures = {
    isConversationalIVRGuideEnabled: false,
    isDeviceActivationGuideEnabled: false,
    isDeviceReplacementInfoEnabled: false,
};

const workspaceCustomerFeatures = {
    isPreviousSessionSummaryEnabled: false,
};

const workspaceExpertFeatures = {
    isOutageAlertInfoEnabled: false,
    isProactiveEnabled: false,
};

export const workspaceFeatures = {
    ...deviceActivationFeatures,
    ...workspaceCustomerFeatures,
    ...workspaceExpertFeatures,
};
