export function ChecklistIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.16673 7.15506L8.08923 6.07756L6.9109 7.25589L9.16673 9.51255L13.0892 5.58922L11.9109 4.41089L9.16673 7.15506Z"
                fill="#A5AAAF"
            />
            <path
                d="M9.16673 12.9884L8.08923 11.9109L6.9109 13.0892L9.16673 15.3459L13.0892 11.4226L11.9109 10.2442L9.16673 12.9884Z"
                fill="#A5AAAF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.00004 1.66675H15C15.9192 1.66675 16.6667 2.41508 16.6667 3.33341V16.6667C16.6667 17.5859 15.9192 18.3334 15 18.3334H5.00004C4.08087 18.3334 3.33337 17.5859 3.33337 16.6667V3.33341C3.33337 2.41508 4.08087 1.66675 5.00004 1.66675ZM5.00004 3.33341V16.6667H15.0009L15 3.33341H5.00004Z"
                fill="#A5AAAF"
            />
        </svg>
    );
}
