import { SalesChecklistBehaviors } from "@expert/shared-types";
import { OfferChecklistState } from "../../stores/useOfferChecklistStore";

export interface OfferChecklistItem {
    displayString: string;
    key: SalesChecklistBehaviors;
    category: string;
    redText?: boolean;
    billingText?: boolean;
}
export interface OfferChecklistSection {
    heading: string;
    disabled: (data: OfferChecklistState["data"]) => boolean;
    content: OfferChecklistItem[];
}

export const offerChecklistData: OfferChecklistSection[] = [
    {
        heading: "Transition statement",
        disabled: (data: OfferChecklistState["data"]): boolean => data.TRANSITION,
        content: [
            {
                displayString: "“A separate coverage from your phone insurance”",
                key: "TRANSITION",
                category: "Transition statement",
                redText: true,
            },
        ],
    },
    {
        heading: "Plan benefits",
        disabled: (data: OfferChecklistState["data"]): boolean =>
            data.FUTURE_DEVICES && data.NON_VERIZON_DEVICES && data.PROTECTION,
        content: [
            {
                displayString: "Repair, replacement, or reimbursement for eligible home tech",
                key: "PROTECTION",
                category: "Plan benefits",
            },
            {
                displayString: "Covers future eligible home tech purchases—no receipts needed",
                key: "FUTURE_DEVICES",
                category: "Plan benefits",
            },
            {
                displayString: "Covers eligible products—both Verizon and non-Verizon",
                key: "NON_VERIZON_DEVICES",
                category: "Plan benefits",
            },
        ],
    },
    {
        heading: "Enrollment transparency",
        disabled: (data: OfferChecklistState["data"]): boolean => data.BILLING && data.ASK_SALE,
        content: [
            {
                displayString: "“$25 per month, plus tax”",
                key: "BILLING",
                category: "Enrollment transparency",
                redText: true,
                billingText: true,
            },
            {
                displayString: "“May I get you enrolled today?”",
                key: "ASK_SALE",
                category: "Enrollment transparency",
                redText: true,
            },
        ],
    },
];
