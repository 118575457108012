interface SummaryResultsParams {
    summary?: string;
    mdn?: string;
    validAccountNumber: boolean;
}

export const validateSummary = ({
    summary,
    mdn,
    validAccountNumber,
}: SummaryResultsParams): { isError: boolean; response?: string } => {
    if (!mdn) {
        return {
            isError: true,
            response: "MDN is undefined",
        };
    }

    if (!validAccountNumber) {
        return {
            isError: true,
            response: "Account number is invalid, it’s possible this customer does not have a BAN",
        };
    }

    if (!summary) {
        return {
            isError: true,
            response: "Session summary is undefined",
        };
    }

    return {
        isError: false,
    };
};
