import { Button, Flex, ScrollArea, Table, Text } from "@mantine/core";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { CaretLeft, CaretRight } from "../common-ui";
import { useSetupSsoToken } from "../auth";
import { withPolymorphicClickAnalytics } from "../analytics";
import { AsurionLogo } from "../common-ui/assets/image/AsurionLogo";
import { useQuery } from "@tanstack/react-query";
import { getCoachStats } from "../hooks/useCoachStats";

export default function PerformanceMetrics(): React.JSX.Element {
    useSetupSsoToken();

    const headers = [
        "Expert name",
        "Calls with checklist",
        "Enrollments",
        "Avg # GPS verbatims used",
        "Avg # benefits used",
        "Calls with transition verbatim",
        "Calls with price verbatim",
        "Calls with ask for sale verbatim",
        "Calls with benefit: RRR",
        "Calls with benefit: future devices",
        "Calls with benefit: Non-Verizon devices",
    ];

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault("America/Chicago");
    const now = dayjs();
    const [selectedDate, setSelectedDate] = useState<Dayjs>(now);
    const formattedDate = dayjs(selectedDate).format("ddd, MMM D");
    const isToday = dayjs(selectedDate).isSame(now, "day");
    const AnalyticsButton = withPolymorphicClickAnalytics(Button, "Click");

    const goToPreviousDay = () => {
        setSelectedDate((prevDate) => prevDate.subtract(1, "day"));
    };

    const goToNextDay = () => {
        if (!isToday) {
            setSelectedDate((prevDate) => prevDate.add(1, "day"));
        }
    };

    const { data } = useQuery({
        queryKey: [`sales-leaderboard`, selectedDate],
        queryFn: async () => getCoachStats(selectedDate, selectedDate),
        refetchInterval: 20000,
    });

    return (
        <>
            <Flex top="0px" left="0px" right="0px">
                <Flex h="1.8rem" ml="1.5rem" mt="0.85rem" mb="0.85rem">
                    <AsurionLogo />
                </Flex>
            </Flex>
            <Flex ml="4rem" mt="1.5rem" justify="center">
                <Flex direction="column">
                    <Text size="1.25rem" fw={700}>
                        Full offer checklist performance
                    </Text>
                    <Flex align="center" pt="1rem">
                        <AnalyticsButton
                            analytics={{
                                actionId: "MetricsPreviousDay",
                            }}
                            onClick={goToPreviousDay}
                            variant="transparent"
                            c="dark.2"
                            p="5px"
                        >
                            <CaretLeft width={8} height={13} />
                        </AnalyticsButton>

                        <Text size="1rem" pl="1.5rem" pr="1.5rem">
                            {formattedDate}
                        </Text>

                        <AnalyticsButton
                            analytics={{
                                actionId: "MetricsNextDay",
                            }}
                            onClick={goToNextDay}
                            variant="transparent"
                            c="dark.2"
                            p="5px"
                            disabled={isToday}
                        >
                            <CaretRight width={8} height={13} />
                        </AnalyticsButton>
                    </Flex>
                    <Flex mah="500px" h="100%" mt="1rem" mr="3rem">
                        <ScrollArea mah="500px" maw="910px">
                            <Table
                                w="auto"
                                striped
                                highlightOnHover
                                withColumnBorders
                                highlightOnHoverColor="asurion.5"
                                stickyHeader
                            >
                                <Table.Thead>
                                    <Table.Tr>
                                        {headers.slice(0, 1).map((header) => (
                                            <Table.Th
                                                key={header}
                                                style={{
                                                    position: "sticky",
                                                    left: 0,
                                                    zIndex: 1000,
                                                    backgroundColor: "var(--mantine-color-dark-7)",
                                                }}
                                            >
                                                <Text
                                                    style={{ textWrap: "nowrap" }}
                                                    size="0.875rem"
                                                    mt="0.5rem"
                                                    mb="0.5rem"
                                                    fw={600}
                                                >
                                                    {header}
                                                </Text>
                                            </Table.Th>
                                        ))}
                                        {headers.slice(1).map((header) => (
                                            <Table.Th key={header}>
                                                <Text
                                                    style={{ textWrap: "nowrap", zIndex: 1 }}
                                                    size="0.875rem"
                                                    mt="0.5rem"
                                                    mb="0.5rem"
                                                    fw={600}
                                                >
                                                    {header}
                                                </Text>
                                            </Table.Th>
                                        ))}
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>
                                    {data?.map(
                                        ({
                                            employeeId,
                                            employeeName,
                                            numCallsWithChecklist,
                                            numEnrollments,
                                            numCallsWithTransitionVerbatim,
                                            numCallsWithPriceVerbatim,
                                            numCallsWithAskForSaleVerbatim,
                                            numCallsWithProtectionVerbatim,
                                            numCallsWithFutureDevicesVerbatim,
                                            numCallsWithNonVerizonVerbatim,
                                        }) => (
                                            <Table.Tr key={employeeId}>
                                                <Table.Td
                                                    style={{
                                                        position: "sticky",
                                                        left: 0,
                                                        backgroundColor: "var(--mantine-color-dark-7)",
                                                        zIndex: 2,
                                                    }}
                                                >
                                                    {employeeName}
                                                </Table.Td>
                                                <Table.Td>{numCallsWithChecklist}</Table.Td>
                                                <Table.Td>{numEnrollments}</Table.Td>
                                                <Table.Td>
                                                    {Math.round(
                                                        ((numCallsWithTransitionVerbatim +
                                                            numCallsWithPriceVerbatim +
                                                            numCallsWithAskForSaleVerbatim) /
                                                            numCallsWithChecklist || 0) * 10,
                                                    ) / 10}
                                                </Table.Td>
                                                <Table.Td>
                                                    {Math.round(
                                                        ((numCallsWithProtectionVerbatim +
                                                            numCallsWithFutureDevicesVerbatim +
                                                            numCallsWithNonVerizonVerbatim) /
                                                            numCallsWithChecklist || 0) * 10,
                                                    ) / 10}
                                                </Table.Td>
                                                <Table.Td>{numCallsWithTransitionVerbatim}</Table.Td>
                                                <Table.Td>{numCallsWithPriceVerbatim}</Table.Td>
                                                <Table.Td>{numCallsWithAskForSaleVerbatim}</Table.Td>
                                                <Table.Td>{numCallsWithProtectionVerbatim}</Table.Td>
                                                <Table.Td>{numCallsWithFutureDevicesVerbatim}</Table.Td>
                                                <Table.Td>{numCallsWithNonVerizonVerbatim}</Table.Td>
                                            </Table.Tr>
                                        ),
                                    )}
                                </Table.Tbody>
                            </Table>
                        </ScrollArea>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
}
