import { type SolveGuidedFlow, defaultErrorStep, GuideMenu } from "@expert/solve-tools";
import { CopyButton } from "@expert/common-ui";
import { Divider, List, Stack, Text } from "@mantine/core";
import { type DeviceSource, modelItems, DeviceActivationFlowId, DeviceActivationFirstStepId } from "../types";
import { type DeviceActivationStore } from "../store";
import { isiPhone14OrNewer } from "./isiPhone14OrNewer";
import { ActivationInfo } from "./ActivationInfo";
import { isSupportedDevice } from "./isSupportedDevice";
import { ActivationInfoTooltip } from "./ActivationInfoTooltip";

const acssActivationSteps = {
    eSim: [
        "Go to Service change > Provisional ID > Validate and submit",
        "Verify the IMEI",
        "Go to Service change > enter IMEI > Validate and submit",
    ],
    physicalSim: [
        "Go to the account > Devices tab > Choose the correct phone number",
        "Enter the new device and SIM ID (SecurePIN will auto-populate)",
        "Choose the reason for the change from the dropdown > Validate",
        "Review all info > Submit",
        "Choose today’s date > Update",
    ],
};

type DeviceActivationContext = Omit<DeviceActivationStore, "setDeviceReplacement"> & {
    isConversationalIVRGuideEnabled?: boolean;
};

export function getDeviceActivationGuide({
    device: { imei, iccid, model: newModel, previousModel, source },
    ivrInfo,
    setDeviceSource,
    setNewDeviceModel,
    setOldDeviceModel,
    swapPendingDevice,
    validationError,
    setValidationError,
    isConversationalIVRGuideEnabled,
    incrementActivationAttempts,
    activationAttempts,
    reset,
}: DeviceActivationContext): SolveGuidedFlow {
    const deviceSourceNextStepId =
        source === "AsurionClaims"
            ? "TransferDRW"
            : source === "Verizon"
              ? "PurchaseDateCheck30Days"
              : "PurchaseDateCheck15Days";

    const activationFailedNextStepId =
        activationAttempts === 0 ? "DeleteESims" : activationAttempts === 1 ? "FactoryReset" : "NetworkIssueCheck";

    const physicalSimActivationFailedNextStepId =
        activationAttempts === 0
            ? "RestartDevice"
            : activationAttempts === 1
              ? "SimProvisioningCheck"
              : "PhysicalSimServiceCheck";

    const isNewDeviceiPhone14OrNewer = isiPhone14OrNewer(newModel);
    const isOldDeviceiPhone14OrNewer = isiPhone14OrNewer(previousModel);
    const isSupportedDeviceModel = isSupportedDevice(newModel);
    const hasTooltipInfo = !!imei || !!iccid || !!ivrInfo;

    return {
        flowId: DeviceActivationFlowId,
        firstStepId: DeviceActivationFirstStepId,
        tag: "Device activation",
        tooltip: hasTooltipInfo ? <ActivationInfoTooltip imei={imei} iccid={iccid} {...ivrInfo} /> : undefined,
        feedbackOptions: {
            title: "Help us improve! Why didn’t you use the activation flow?",
            options: [
                "Not activation call",
                "Device not supported",
                "I’ll use it later in call",
                "Didn’t work",
                "Have to transfer",
                "Other",
            ],
        },
        reset,
        steps: {
            // CONVERSATIONAL IVR ONLY
            ShowConversationalIVRDetails: {
                stepId: "ShowConversationalIVRDetails",
                tag: "Info from IVR",
                content: (
                    <ActivationInfo
                        imei={imei}
                        iccid={iccid}
                        model={newModel}
                        isSupportedDeviceModel={isSupportedDeviceModel}
                        isConversationalIVRGuideEnabled={isConversationalIVRGuideEnabled}
                        {...ivrInfo}
                    />
                ),
                progress: 100,
                variant: "Default",
                actions: [{ label: "Got it", nextStepAction: "CloseGuide" }],
            },
            // SHARED STEPS
            StartDeviceActivation: {
                stepId: "StartDeviceActivation",
                content: (
                    <ActivationInfo
                        imei={imei}
                        iccid={iccid}
                        model={newModel}
                        isSupportedDeviceModel={isSupportedDeviceModel}
                        isConversationalIVRGuideEnabled={isConversationalIVRGuideEnabled}
                        {...ivrInfo}
                    />
                ),
                progress: 0,
                variant: "Default",
                actions: isSupportedDeviceModel
                    ? [{ label: "Start activation", nextStepId: "HasAttemptedActivationCheck" }]
                    : [
                          { label: "Close", nextStepAction: "CloseGuide" },
                          {
                              label: "Activate iPhone or Samsung phone",
                              nextStepId: "HasAttemptedActivationCheck",
                              variant: "subtle",
                          },
                      ],
            },
            RestartWithNewInfo: {
                stepId: "RestartWithNewInfo",
                content: (
                    <ActivationInfo
                        imei={imei}
                        iccid={iccid}
                        model={newModel}
                        isSupportedDeviceModel={isSupportedDeviceModel}
                        {...ivrInfo}
                        isForRestart
                    />
                ),
                progress: 20,
                variant: "Default",
                actions: [
                    { label: "Yes, restart", nextStepId: "HasAttemptedActivationCheck", onClick: swapPendingDevice },
                    { label: "No, continue with the original", nextStepAction: "PreviousStep" },
                ],
            },
            HasAttemptedActivationCheck: {
                stepId: "HasAttemptedActivationCheck",
                content: (
                    <Text>
                        Have you already <strong>tried to activate</strong> the device?
                    </Text>
                ),
                progress: 20,
                variant: "Default",
                actions: [
                    { label: "Yes", nextStepId: "SOSCheck" },
                    { label: "No", nextStepId: "NewDeviceModelCheck" },
                ],
            },
            SOSCheck: {
                stepId: "SOSCheck",
                content: (
                    <Text>
                        Do you see <strong>SOS</strong> or <strong>no service</strong> where signal bars should be?
                    </Text>
                ),
                progress: 30,
                variant: "Default",
                actions: [
                    { label: "Yes", nextStepId: "ActivateESIMInACSS" },
                    { label: "No", nextStepId: "NewDeviceModelCheck" },
                ],
            },
            NewDeviceModelCheck: {
                stepId: "NewDeviceModelCheck",
                content: (
                    <Stack align="flex-start">
                        <Text>
                            What <strong>kind of device</strong> are we activating?
                        </Text>
                        <GuideMenu
                            key="guide-menu-new-device"
                            label="Device type"
                            defaultValue={newModel}
                            actionId="NewDeviceMenu"
                            items={modelItems}
                            onClick={(device) => setNewDeviceModel(device.label)}
                            validationError={validationError}
                        />
                    </Stack>
                ),
                progress: 20,
                variant: "Default",
                actions: [
                    {
                        label: "Continue",
                        nextStepId: isSupportedDeviceModel ? "OldDeviceCheck" : "UnsupportedDevice",
                        validate: () => {
                            if (!newModel) {
                                const error = "Choose a device";
                                setValidationError(error);
                                return error;
                            }
                        },
                    },
                ],
            },
            UnsupportedDevice: {
                stepId: "UnsupportedDevice",
                content: (
                    <Text>
                        Sorry, the activation flow only supports <strong>iPhones and Samsung phones</strong> right now,
                        but will support more soon!
                    </Text>
                ),
                progress: 100,
                variant: "Default",
                actions: [{ label: "Close guide", nextStepAction: "CloseGuide" }],
            },
            OldDeviceCheck: {
                stepId: "OldDeviceCheck",
                content: (
                    <Text>
                        Is the <strong>old device</strong> available and still working?
                    </Text>
                ),
                progress: 30,
                variant: "Default",
                actions: [
                    { label: "Yes", nextStepId: "OldDeviceModelCheck" },
                    {
                        label: "No",
                        nextStepId: isNewDeviceiPhone14OrNewer ? "ActivateESIMInACSS" : "OldSimAvailabilityCheck",
                    },
                ],
            },
            OldDeviceModelCheck: {
                stepId: "OldDeviceModelCheck",
                content: (
                    <Stack align="flex-start">
                        <Text>
                            What kind is the <strong>old device?</strong>
                        </Text>
                        <GuideMenu
                            key="guide-menu-old-device"
                            label="Device type"
                            defaultValue={previousModel}
                            actionId="OldDeviceMenu"
                            items={modelItems}
                            onClick={(device) => setOldDeviceModel(device.label)}
                            validationError={validationError}
                        />
                    </Stack>
                ),
                progress: 20,
                variant: "Default",
                actions: [
                    {
                        label: "Continue",
                        nextStepId: isOldDeviceiPhone14OrNewer ? "TransferContentOffer" : "WifiCheck",
                        validate: () => {
                            if (!previousModel) {
                                const error = "Choose a device";
                                setValidationError(error);
                                return error;
                            }
                        },
                    },
                ],
            },
            WifiCheck: {
                stepId: "WifiCheck",
                content: (
                    <Text>
                        Is the new device <strong>connected to Wi-Fi</strong>?
                    </Text>
                ),
                progress: 30,
                variant: "Default",
                actions: [
                    { label: "Yes", nextStepId: "TransferContentOffer" },
                    {
                        label: "No",
                        nextStepId: isNewDeviceiPhone14OrNewer ? "ActivateESIMInACSS" : "OldSimAvailabilityCheck",
                    },
                ],
            },
            TransferContentOffer: {
                stepId: "TransferContentOffer",
                content: (
                    <Stack>
                        <Text>
                            Do you want to <strong>transfer content</strong> now?
                        </Text>
                        <Text size="sm">The customer will need account sign-in information.</Text>
                    </Stack>
                ),
                progress: 30,
                variant: "Default",
                actions: [
                    { label: "Yes, transfer", nextStepId: "TransferContent" },
                    {
                        label: "No, skip transfer",
                        nextStepId: isNewDeviceiPhone14OrNewer ? "ActivateESIMInACSS" : "OldSimAvailabilityCheck",
                    },
                ],
            },
            TransferContent: {
                stepId: "TransferContent",
                content: (
                    <Stack>
                        <Text>
                            Let’s transfer content from the <strong>old device to the new one</strong>
                        </Text>
                        <Text size="sm">Need help? Ask Assist in the chat.</Text>
                    </Stack>
                ),
                progress: 40,
                variant: "Default",
                actions: [
                    {
                        label: "I’m done transferring content",
                        nextStepId: isNewDeviceiPhone14OrNewer ? "ActivateESIMInACSS" : "OldSimAvailabilityCheck",
                    },
                ],
            },
            TestDeviceSuccess: {
                stepId: "TestDeviceSuccess",
                content: <Text>Great job—you’re all set!</Text>,
                progress: 100,
                variant: "Success",
                actions: [
                    {
                        label: "Close guide",
                        nextStepAction: "CloseGuide",
                    },
                ],
            },
            DefaultError: {
                ...defaultErrorStep,
                actions: [
                    {
                        label: "Close guide",
                        nextStepAction: "CloseGuide",
                    },
                ],
            },
            // ESIM STEPS
            ActivateESIMInACSS: {
                stepId: "ActivateESIMInACSS",
                content: (
                    <Stack gap="xs">
                        <Text>
                            Let’s {activationAttempts > 0 ? "retry" : "start"} the activation in <strong>ACSS</strong>
                        </Text>
                        {imei ? (
                            <List size="sm" spacing="0.375rem" pl="sm">
                                <List.Item>
                                    IMEI: {imei} <CopyButton value={imei} />
                                </List.Item>
                            </List>
                        ) : (
                            <Text size="sm">Confirm the IMEI first.</Text>
                        )}
                        <Divider color="primary.4" />
                        <List size="sm" spacing="0.375rem" pl="sm" type="ordered">
                            {acssActivationSteps.eSim.map((step) => (
                                <List.Item key={step}>{step}</List.Item>
                            ))}
                        </List>
                    </Stack>
                ),
                progress: 40,
                variant: "Default",
                actions: [
                    {
                        label: "Continue to eSIM",
                        nextStepId: "AddESim",
                    },
                ],
            },
            AddESim: {
                stepId: "AddESim",
                content: (
                    <Stack gap="xs">
                        {activationAttempts > 0 ? (
                            <Text>
                                Ok, let’s retry <strong>adding an eSIM</strong>
                            </Text>
                        ) : (
                            <Text>
                                Great! Here’s how to <strong>add an eSIM</strong>
                            </Text>
                        )}
                        <List size="sm" spacing="0.375rem" pl="sm">
                            <List.Item>{"iPhone: Settings > Cellular > Set up > add eSIM"}</List.Item>
                            <List.Item>{"Samsung: Settings > SIM card manager > Add mobile plan"}</List.Item>
                        </List>
                    </Stack>
                ),
                progress: 70,
                variant: "Default",
                actions: [
                    { label: "It’s activated!", nextStepId: "ESimTestDeviceCall" },
                    {
                        label: "Activation failed",
                        nextStepId: activationFailedNextStepId,
                        onClick: () => {
                            incrementActivationAttempts();
                        },
                    },
                ],
            },
            ESimTestDeviceCall: {
                stepId: "ESimTestDeviceCall",
                content: (
                    <Text>
                        Let’s try making an <strong>outbound call and text</strong>. Did everything work?
                    </Text>
                ),
                progress: 90,
                variant: "Default",
                actions: [
                    { label: "Yes", nextStepId: "TestDeviceSuccess" },
                    { label: "No", nextStepId: "DeviceIssue" },
                ],
            },
            // eSim device issue
            DeviceIssue: {
                stepId: "DeviceIssue",
                content: (
                    <Stack gap="sm">
                        <Text>
                            Hmm, it seems like there may be an <strong>issue with the device</strong>
                        </Text>
                        <Text size="sm">Need help troubleshooting? Ask Assist in the chat.</Text>
                    </Stack>
                ),
                progress: 100,
                variant: "Default",
                actions: [{ label: "Close guide", nextStepAction: "CloseGuide" }],
            },
            // eSim failure 1
            DeleteESims: {
                stepId: "DeleteESims",
                content: (
                    <Stack gap="sm" align="flex-start">
                        <Text>
                            Ok, let’s <strong>delete all eSIMs</strong>
                        </Text>
                        <List size="sm" spacing="0.375rem" pl="sm">
                            <List.Item>
                                {"iPhone: Settings > General > Transfer or Reset iPhone > Reset > Delete All eSIMs"}
                            </List.Item>
                            <List.Item>
                                {
                                    "Samsung: Settings > Connections > SIM Manager > Select SIM to remove > Tap Remove, then Remove again to confirm"
                                }
                            </List.Item>
                        </List>
                    </Stack>
                ),
                progress: 90,
                variant: "Default",
                actions: [{ label: "Continue to reset network settings", nextStepId: "ResetNetworkSettings" }],
            },
            ResetNetworkSettings: {
                stepId: "ResetNetworkSettings",
                content: (
                    <Stack gap="sm" align="flex-start">
                        <Text>
                            Now let’s <strong>reset network settings</strong>
                        </Text>
                        <Text size="sm">iPhone:</Text>
                        <List size="sm" spacing="0.375rem" pl="sm">
                            <List.Item>
                                {
                                    "Settings > General > Transfer or Reset iPhone (or Reset) > Reset > Reset Network Settings > Enter passcode > Tap Reset Network Settings again"
                                }
                            </List.Item>
                        </List>
                        <Text size="sm">Samsung:</Text>
                        <List size="sm" spacing="0.375rem" pl="sm">
                            <List.Item>
                                {
                                    "General management >  Reset > Reset network settings > Enter passcode if asked > Reset Settings > Reset"
                                }
                            </List.Item>
                        </List>
                    </Stack>
                ),
                progress: 90,
                variant: "Default",
                actions: [{ label: "Continue to ACSS step", nextStepId: "ActivateESIMInACSS" }],
            },
            // eSim failure 2
            FactoryReset: {
                stepId: "FactoryReset",
                content: (
                    <Stack>
                        <Text>
                            Let’s try a <strong>factory reset</strong>
                        </Text>
                        <Text size="sm">Need help with this step? Ask Assist in the chat.</Text>
                        <Text size="xs">
                            *Only do a factory reset as a last resort. It will erase everything and restore it to its
                            original settings. Make sure to back up data first.
                        </Text>
                    </Stack>
                ),
                progress: 70,
                variant: "Default",
                actions: [
                    {
                        label: "Continue",
                        nextStepId: "ActivateESIMInACSS",
                    },
                ],
            },
            // eSim failure 3
            NetworkIssueCheck: {
                stepId: "NetworkIssueCheck",
                content: (
                    <Stack>
                        <Text>
                            Is it a <strong>network-related</strong> issue?
                        </Text>
                        <Text size="sm">
                            ACSS may show ‘No MDN available’, switch order, or future-dated order errors if so.
                        </Text>
                    </Stack>
                ),
                progress: 70,
                variant: "Default",
                actions: [
                    {
                        label: "Yes",
                        nextStepId: "TransferTier2",
                    },
                    {
                        label: "No",
                        nextStepId: "DeviceSourceCheck",
                    },
                ],
            },
            TransferTier2: {
                stepId: "TransferTier2",
                content: (
                    <Text>
                        Ok, let’s transfer to <strong>tier 2</strong>
                    </Text>
                ),
                progress: 100,
                variant: "Default",
                actions: [
                    {
                        label: "Close guide",
                        nextStepAction: "CloseGuide",
                    },
                ],
            },
            DeviceSourceCheck: {
                stepId: "DeviceSourceCheck",
                content: (
                    <Stack align="flex-start">
                        <Text>Where did you get the new device?</Text>
                        <GuideMenu
                            key="guide-menu-device-supplier"
                            label="Retailer"
                            actionId="DeviceSupplierMenu"
                            items={[
                                { label: "Asurion claims", actionId: "AsurionClaims" },
                                { label: "Verizon store or website", actionId: "Verizon" },
                                { label: "3rd party (Amazon, Apple, etc)", actionId: "ThirdParty" },
                            ]}
                            onClick={(value) => setDeviceSource(value.actionId as DeviceSource)}
                        />
                    </Stack>
                ),
                progress: 70,
                variant: "Default",
                actions: [
                    {
                        label: "Continue",
                        nextStepId: deviceSourceNextStepId,
                    },
                ],
            },
            TransferDRW: {
                stepId: "TransferDRW",
                content: (
                    <Text>
                        Ok, let’s <strong>transfer to DRW</strong> (device replacement warranty)
                    </Text>
                ),
                progress: 100,
                variant: "Default",
                actions: [
                    {
                        label: "Close guide",
                        nextStepAction: "CloseGuide",
                    },
                ],
            },
            PurchaseDateCheck30Days: {
                stepId: "PurchaseDateCheck30Days",
                content: (
                    <Text>
                        Was it bought in the <strong>last 30 days</strong>?
                    </Text>
                ),
                progress: 90,
                variant: "Default",
                actions: [
                    { label: "Yes", nextStepId: "RequestExchangeVerizon" },
                    { label: "No", nextStepId: "ProcessCLNR" },
                ],
            },
            PurchaseDateCheck15Days: {
                stepId: "PurchaseDateCheck15Days",
                content: (
                    <Text>
                        Was it bought in the <strong>last 15 days</strong>?
                    </Text>
                ),
                progress: 90,
                variant: "Default",
                actions: [
                    { label: "Yes", nextStepId: "RequestExchangeRetailer" },
                    { label: "No", nextStepId: "ProcessCLNR" },
                ],
            },
            RequestExchangeVerizon: {
                stepId: "RequestExchangeVerizon",
                content: (
                    <Text>
                        The customer needs to <strong>request an exchange</strong> from Verizon
                    </Text>
                ),
                progress: 100,
                variant: "Default",
                actions: [
                    {
                        label: "Close guide",
                        nextStepAction: "CloseGuide",
                    },
                ],
            },
            RequestExchangeRetailer: {
                stepId: "RequestExchangeRetailer",
                content: (
                    <Text>
                        The customer needs to <strong>request an exchange</strong> from the retailer
                    </Text>
                ),
                progress: 100,
                variant: "Default",
                actions: [
                    {
                        label: "Close guide",
                        nextStepAction: "CloseGuide",
                    },
                ],
            },
            ProcessCLNR: {
                stepId: "ProcessCLNR",
                content: (
                    <Text>
                        Ok, let’s process a <strong>CLNR</strong> (certified like new replacement)
                    </Text>
                ),
                progress: 100,
                variant: "Default",
                actions: [
                    {
                        label: "Close guide",
                        nextStepAction: "CloseGuide",
                    },
                ],
            },
            // PHYSICAL SIM STEPS
            OldSimAvailabilityCheck: {
                stepId: "OldSimAvailabilityCheck",
                content: (
                    <Text>
                        Is the <strong>old SIM</strong> available?
                    </Text>
                ),
                progress: 50,
                variant: "Default",
                actions: [
                    { label: "Yes", nextStepId: "SimSwap" },
                    { label: "No", nextStepId: "ActivatePhysicalSIMInACSS" },
                ],
            },
            SimSwap: {
                stepId: "SimSwap",
                content: (
                    <Text>
                        Let’s <strong>swap the SIM</strong> and restart the device
                    </Text>
                ),
                progress: 60,
                variant: "Default",
                actions: [{ label: "Continue", nextStepId: "PhysicalSimTestDeviceCall" }],
            },
            ActivatePhysicalSIMInACSS: {
                stepId: "ActivatePhysicalSIMInACSS",
                content: (
                    <Stack>
                        <Text>
                            Let’s {activationAttempts > 0 ? "retry activating" : "activate"} the device and SIM in{" "}
                            <strong>ACSS</strong>
                        </Text>
                        {(imei ?? iccid) ? (
                            <List size="sm" spacing="0.375rem" pl="sm">
                                {imei ? (
                                    <List.Item>
                                        IMEI: {imei} <CopyButton value={imei} />
                                    </List.Item>
                                ) : null}
                                {iccid ? (
                                    <List.Item>
                                        ICCID: {iccid} <CopyButton value={iccid} />
                                    </List.Item>
                                ) : null}
                            </List>
                        ) : (
                            <Text size="sm">You’ll need the IMEI & ICCID.</Text>
                        )}
                        <Divider color="primary.4" />
                        <List size="sm" spacing="0.375rem" pl="sm" type="ordered">
                            {acssActivationSteps.physicalSim.map((step) => (
                                <List.Item key={step}>{step}</List.Item>
                            ))}
                        </List>
                    </Stack>
                ),
                progress: 40,
                variant: "Default",
                actions: [
                    {
                        label: "It’s activated!",
                        nextStepId: "PhysicalSimTestDeviceCall",
                    },
                    {
                        label: "Activation failed",
                        nextStepId: physicalSimActivationFailedNextStepId,
                    },
                ],
            },
            PhysicalSimTestDeviceCall: {
                stepId: "PhysicalSimTestDeviceCall",
                content: (
                    <Text>
                        Let’s try making an <strong>outbound call and text</strong>. Did everything work?
                    </Text>
                ),
                progress: 90,
                variant: "Default",
                actions: [
                    { label: "Yes", nextStepId: "TestDeviceSuccess" },
                    {
                        label: "No",
                        nextStepId: physicalSimActivationFailedNextStepId,
                        onClick: () => {
                            incrementActivationAttempts();
                        },
                    },
                ],
            },
            // physical sim failure 1
            RestartDevice: {
                stepId: "RestartDevice",
                content: <Text>Ok, let’s try restarting the device</Text>,
                progress: 80,
                variant: "Default",
                actions: [
                    {
                        label: "It’s activated!",
                        nextStepId: "PhysicalSimTestDeviceCall",
                    },
                    {
                        label: "Activation failed",
                        nextStepId: "SimProvisioningCheck",
                        onClick: () => {
                            incrementActivationAttempts();
                        },
                    },
                ],
            },
            // physical sim failure 2
            SimProvisioningCheck: {
                stepId: "SimProvisioningCheck",
                content: (
                    <Stack>
                        <Text>Was the SIM provisioned correctly?</Text>
                        <Text size="sm">Confirm the IMEI and ICCID.</Text>
                    </Stack>
                ),
                progress: 80,
                variant: "Default",
                actions: [
                    {
                        label: "Yes",
                        nextStepId: "PhysicalSimServiceCheck",
                    },
                    {
                        label: "No",
                        nextStepId: "ActivatePhysicalSIMInACSS",
                        onClick: () => {
                            incrementActivationAttempts();
                        },
                    },
                ],
            },
            PhysicalSimFactoryReset: {
                stepId: "PhysicalSimFactoryReset",
                content: (
                    <Stack>
                        <Text>
                            Let’s try a <strong>factory reset</strong>
                        </Text>
                        <Text size="sm">Need help with this step? Ask Assist in the chat.</Text>
                        <Text size="xs">
                            *Only do a factory reset as a last resort. It will erase everything and restore it to its
                            original settings. Make sure to back up data first.
                        </Text>
                    </Stack>
                ),
                progress: 70,
                variant: "Default",
                actions: [
                    {
                        label: "Continue",
                        nextStepId: "PhysicalSimTestDeviceCall",
                    },
                ],
            },
            // physical sim failure 3
            PhysicalSimServiceCheck: {
                stepId: "PhysicalSimServiceCheck",
                content: (
                    <Text>
                        Does the device have any <strong>signal bars</strong>?
                    </Text>
                ),
                progress: 80,
                variant: "Default",
                actions: [
                    { label: "Yes", nextStepId: "TransferTier2" },
                    { label: "No", nextStepId: "PhysicalSimFactoryReset" },
                ],
            },
        },
    };
}
