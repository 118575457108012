import { getLogger } from "@expert/logging";
import { addSessionSummaryMessage } from "@soluto-private/expert-workspace-timeline";
import { useCallback, useState } from "react";
import { shallow } from "zustand/shallow";
import { getBackupGaiaSummary, getGaiaSummary } from "../api/sessionSummaryApiClient";
import { getHashedValue, removeCountryCode, useFeatureFlag } from "../../shared-utils";
import { usePartner, useSession } from "../../sdk";
import { useSessionSummaryStore } from "../store/useSessionSummaryStore";
import { useToast } from "../../common-ui";
import { validateSummary } from "../validateSummary";
import { getAppConfigSingleton } from "../../config/appConfigSingleton";
import { useVerifiedCustomerStore } from "../../customer-info";
import { useSessionSummaryDispatchActions } from "./hooks";

const logger = getLogger({ module: "sessionSummary" });

/**
 * Hook for session summary logic to separate from UI components
 */
export const useSummary = () => {
    const appConfig = getAppConfigSingleton();
    const activeSession = useSession();
    const partner = usePartner();
    const toast = useToast();

    const [summaryGenerated, setSummaryGenerated] = useState(false);

    const { summary, submitted, setSessionSummary } = useSessionSummaryStore((state) => state, shallow);
    const { dispatchSessionSummaryStats, dispatchSessionSubmit } = useSessionSummaryDispatchActions();
    const [setSubmitted, autogeneratedSummary] = useSessionSummaryStore((state) => [
        state.setSubmitted,
        state.autogeneratedSummary,
    ]);
    const [mdn, accountNumber] = useVerifiedCustomerStore((state) => [
        state.customer?.mdn,
        state.customer?.accountNumber,
    ]);

    const { data: isGaiaMultiStepSummaryEnabled, isLoading: isLoadingSummaryFeature } = useFeatureFlag(
        "isGaiaMultiStepSummaryEnabled",
        { partner },
    );

    const gaiaSummaryModel = isGaiaMultiStepSummaryEnabled
        ? "post-call-multistep-summarization"
        : "post-call-summarization";

    const accountNumberLength = appConfig.partnersConfig[partner].accountNumberLength;
    const validAccountNumber = accountNumberLength ? accountNumber?.length === accountNumberLength : false;

    const requiresManualCopy = appConfig.partnersConfig[partner].requiresManualCopy;
    const shouldSendSummary = !submitted && summary;

    const setSessionSummaryFromGaia = useCallback(
        async (setSummaryLoaded: (arg0: boolean) => void, agentCallSid?: string) => {
            logger.trace({ agentCallSid, sessionId: activeSession.id, partner }, "Gaia summary fetch check");

            const generatedSummary = agentCallSid
                ? await getGaiaSummary(
                      { callSid: agentCallSid, sessionId: activeSession.id, partner },
                      gaiaSummaryModel,
                  )
                : await getBackupGaiaSummary({ sessionId: activeSession.id, partner }, gaiaSummaryModel);

            setSessionSummary({ value: generatedSummary, autoGenerated: true });
            setSummaryLoaded(true);
            setSummaryGenerated(!!generatedSummary);
        },
        [activeSession.id, partner, setSessionSummary, gaiaSummaryModel],
    );

    const onSessionSummaryCompleted = useCallback(
        async (
            closeModal: () => void,
            update: string,
            expirationTimestamp: number,
            skipSend?: boolean,
            agentCallSid?: string,
        ) => {
            if (update) addSessionSummaryMessage({ summary: update, header: "Call summary" });
            if (skipSend) {
                dispatchSessionSummaryStats({
                    agentCallSid,
                    update,
                    autogeneratedSummary,
                    expirationTimestamp,
                    timeRanOut: false,
                    summaryWasCopiedToClipboard: true,
                    summaryWasSubmittedViaACSS: false,
                    summaryWasSubmittedViaPartnerTool: false,
                    gaiaSummaryModel,
                });
                setSubmitted(true);
                closeModal();
                return;
            }

            const { isError, response } = validateSummary({ mdn, validAccountNumber, summary: update });

            if (!mdn || isError) {
                logger.error(response ?? "Something went wrong");
                toast.error("Something went wrong");
                closeModal();
                return;
            }

            const mdnFormatted = removeCountryCode(mdn);
            const mdnHashed = await getHashedValue(mdnFormatted);

            const extraSessionSummaryPayloadProps = appConfig.partnersConfig[partner].extraSessionSummaryPayloadProps;

            const sessionSummaryPayload = {
                accountNo: accountNumber,
                remarkText: update,
                ...extraSessionSummaryPayloadProps,
            };

            // Summary -> partner tool (ACSS/Customer Central)
            dispatchSessionSubmit({ agentCallSid, mdn: mdnFormatted, mdnHashed, sessionSummaryPayload });

            dispatchSessionSummaryStats({
                agentCallSid,
                update,
                autogeneratedSummary,
                expirationTimestamp,
                summaryWasCopiedToClipboard: false,
                summaryWasSubmittedViaACSS: true,
                summaryWasSubmittedViaPartnerTool: true,
                timeRanOut: false,
                gaiaSummaryModel,
            });

            setSubmitted(true);
            closeModal();
            toast.success("Summary sent");
        },
        [
            mdn,
            accountNumber,
            appConfig.partnersConfig,
            partner,
            setSubmitted,
            toast,
            dispatchSessionSummaryStats,
            validAccountNumber,
            autogeneratedSummary,
            gaiaSummaryModel,
            dispatchSessionSubmit,
        ],
    );

    const onDismiss = useCallback(
        (closeModal: () => void, agentCallSid?: string, { checkSummary = true } = {}) => {
            logger.trace("Session summary dismissed");
            closeModal();
            if (checkSummary && shouldSendSummary) {
                dispatchSessionSummaryStats({
                    agentCallSid,
                    update: summary,
                    autogeneratedSummary,
                    expirationTimestamp: 0,
                    timeRanOut: true,
                    summaryWasCopiedToClipboard: true,
                    summaryWasSubmittedViaACSS: false,
                    summaryWasSubmittedViaPartnerTool: false,
                });

                toast.success("Session summary copied to clipboard");
                void navigator.clipboard.writeText(summary);
            }
        },
        [autogeneratedSummary, dispatchSessionSummaryStats, shouldSendSummary, summary, toast],
    );

    return {
        isLoadingSummaryFeature,
        summaryGenerated,
        validAccountNumber,
        requiresManualCopy,
        summary,

        setSessionSummaryFromGaia,
        onSessionSummaryCompleted,
        onDismiss,
    };
};
